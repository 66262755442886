import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../configuration-management/services/account.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../../../configuration-management/models/user.interface';
import { MatSnackBar } from '@angular/material/snack-bar';

/** @title Sidenav open & close behavior */
@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {
  currentUser: User;
  constructor(
    private accountService: AccountService,
    private router: Router,
    public snackBar: MatSnackBar,

  ) {
    this.accountService.currentUser.subscribe(x => this.currentUser = x);
  }

  userLoginForm: FormGroup;

  ngOnInit() {

    if (this.currentUser != null) {
      console.log(this.currentUser);
      this.router.navigateByUrl(`client-management/client-record/${this.currentUser.clientId}`);
    }

    this.userLoginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });

  }

  onSubmit(loginFormGroup) {
    if (this.userLoginForm.valid) {
      this.accountService.login(loginFormGroup.username, loginFormGroup.password).subscribe(
        res => {

          this.router.navigateByUrl(`client-management/client-record/${this.currentUser.clientId}`);
        },
        error => {
          this.snackBar.open('Login Attempt Failed', 'ERROR', {
            duration: 2000,
        });
        }
      );
    }


  }



}
