import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { User } from '../configuration-management/models/user.interface';
import { ConnectionService } from './offline-detection';
import { ConfigLoaderService } from '../config-loader.service';
import { ConfigModel } from '../config.model';
import { storage } from '../shared/local-storage/local-storage.service';

@Injectable()
export class BaseService {

    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    isConnected = true;
    public onlineEvent: Observable<Event>;
    public offlineEvent: Observable<Event>;
    public subscriptions: Subscription[] = [];
    public connectionStatusMessage: string;
    public connectionStatus: string;
    public environment: ConfigModel;
    constructor(
        protected http: HttpClient,
        private connectionService: ConnectionService,
        private configService: ConfigLoaderService
    ) {
      this.currentUserSubject = new BehaviorSubject<User>(storage.getItem('currentUser', {} as User));
        this.currentUser = this.currentUserSubject.asObservable();
        this.connectionService.monitor().subscribe(currentState => {
            if (currentState.hasNetworkConnection && currentState.hasInternetAccess) {
              this.isConnected = true;
            } else {
              this.isConnected = false;
            }
          });
        this.environment = this.configService.config;
    }

    headers() {
        return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    }

    fileHeaders() {
        return {
            responseType: 'blob',
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', })
        } as any;
    }

    protected get apiUrl(): string {
        return this.configService.config.apiUrl;
    }



}
