import { Injectable } from '@angular/core';
import { BaseService } from '../../helpers/base.service';
import { Observable } from 'rxjs/internal/Observable';
import { User, UserAccount, UserAccounts, CreateUser } from '../models/user.interface';
import { map } from 'rxjs/operators';
import { storage } from 'src/app/shared/local-storage/local-storage.service';
import {Local} from "protractor/built/driverProviders";
import {LocalStorageNames} from "../../shared/local-storage/local-storage.enum";

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {

  doctors: UserAccount[];
  public login(username: string, password: string): Observable<any> {

    const route = `${this.apiUrl}/Account/ClientLogin`;
    const body = JSON.stringify({ username, password });

    return this.http.post<any>(route, body, this.headers()).pipe(map(user => {

      storage.setItem('currentUser', user);
      this.currentUserSubject.next(user);

      return user;
    }));
  }


  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get isSystemAdmin(): boolean {

    if (this.currentUserSubject.value.roles) {
      const roles = this.currentUserSubject.value.roles.filter(x => x === 'SystemAdministrator');

      if (roles.length > 0) {
        return true;
      }
    }
    return false;
  }

  public get canFinalize(): boolean {

    if (this.currentUserSubject.value.roles) {
      const roles = this.currentUserSubject.value.roles.filter(x =>
        x === 'SystemAdministrator'
        || x === 'BusinessOffice'
        || x === 'FrontOffice');

      if (roles.length > 0) {
        return true;
      }
    }
    return false;
  }

  logout() {
    storage.removeItem(LocalStorageNames.CurrentUser);
    this.currentUserSubject.next(null);
  }

  public getUserAccountsList() {

    const route = `${this.apiUrl}/Account/GetAccounts`;

    return this.http.get<UserAccounts>(route, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public getTechs(): Observable<UserAccount[]> {

    const route = `${this.apiUrl}/User/GetTechs`;

    return this.http.get<UserAccount[]>(route, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public getCalendarDoctors(date: Date): Observable<UserAccount[]> {
    date = new Date(date.toString());
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    const route = `${this.apiUrl}/User/GetCalendarDoctors`;
    const body = JSON.stringify(date);

    return this.http.post<UserAccount[]>(route, body, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public getCalendarDoctorsWeek(date: Date): Observable<UserAccount[]> {
    var startDate = new Date(date.toString());
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const route = `${this.apiUrl}/User/GetDoctorsWeeklyCalendar`;
    const body = JSON.stringify(startDate);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }


  public setCalendarDoctors(userId: string, calendarDoctors: string[]): any {

    const route = `${this.apiUrl}/User/CalendarDoctors/${userId}`;

    return this.http.post<any>(route, calendarDoctors, this.headers()).pipe(map(response => {

      return response;
    }));
  }


  public getFinishedUsers(): Observable<UserAccount[]> {

    const route = `${this.apiUrl}/User/GetFinishedUsers`;

    return this.http.get<UserAccount[]>(route, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public createAccount(createAccountModel: CreateUser) {
    const route = `${this.apiUrl}/Account/AddUser`;
    const body = createAccountModel;
    console.log(createAccountModel);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getIncompleteAccount(_userId, _token) {
    const route = `${this.apiUrl}/User/GetIncompleteAccount`;
    const body = {
      userId: _userId,
      token: _token
    };
    console.log(body);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public confirmAccount(confirmAccountModel: any) {
    const route = `${this.apiUrl}/User/CompleteAccount`;
    const body = confirmAccountModel;
    console.log(confirmAccountModel);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public resendConfirmation(_email: string) {
    const route = `${this.apiUrl}/User/ResendConfirmation`;
    const body = { email: _email };
    console.log(_email);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public sendForgotPasswordEmail(_email: string) {
    const route = `${this.apiUrl}/User/ClientForgotPasswordEmail`;
    const body = { email: _email };
    console.log(_email);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateAccount(updateAccountModel: any) {
    const route = `${this.apiUrl}/User/UpdateAccount`;
    const body = updateAccountModel;
    console.log(updateAccountModel);

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
