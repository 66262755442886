import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AccountService } from './configuration-management/services/account.service';
import { Router } from '@angular/router';
import { User } from './configuration-management/models/user.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentUser: User;
  events: string[] = [];
  opened = true;
  shouldRun = true;

  constructor(
    public swUpdate: SwUpdate,
    private accountService: AccountService,
    private router: Router,
  ) {
    this.accountService.currentUser.subscribe(x => this.currentUser = x);

    
   }

  ngOnInit() {



    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('A newer version of the application is available. Load the new version ?')) {
          window.location.reload();
        }
      });
    }

  }

  logout() {
    this.accountService.logout();
    this.router.navigateByUrl('login');
  }

}
