import { Component, Inject, EventEmitter, Output, OnChanges, SimpleChanges, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Client } from 'src/app/client-management/models/client.interface';
import { Note } from './note.model';
import { Page } from '../../configuration-management/models/user.interface';


@Component({
    selector: 'app-notes',
    templateUrl: 'notes.component.html',
    styleUrls: ['notes.component.scss']
})
export class NotesComponent implements OnInit {

    @Output() outputNotes = new EventEmitter<Note[]>();
    @Input() notes: Note[];
    @Input() isPrivate: boolean;
    noteText: string;
    isImportant = false;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar
    ) { }


    ngOnInit() {
      setTimeout(() => {
        console.log(this.notes);
      }, 5000);
      console.log(this.notes);
    }

    addNote() {
        const newNote = {
            id: null,
            text: this.noteText,
            isImportant: this.isImportant,
            isPrivate: this.isPrivate,
            clientId: null,
            patientId: null,
            userId:  null,
            userName: null,
        } as Note;

        this.notes.push(newNote);

        this.isImportant = false;
        this.noteText = null;

        this.updateNotes();
    }

    deleteNote(note: Note) {

        this.notes = this.notes.filter(x => x !== note);

        this.updateNotes();
    }

    updateNotes() {
        this.outputNotes.emit(this.notes);
    }


}
