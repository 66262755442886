import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {VitalUnit} from './vital-unit.model';

@Component({
  selector: 'app-view-weight-vital',
  templateUrl: './view-weight-vital.component.html',
  styleUrls: ['./view-weight-vital.component.css']
})
export class ViewWeightVitalComponent implements OnInit, OnChanges {

  @Input() value: string;
  @Input() unitValue: string;
  @Input() abbreviate = false;

  unit: VitalUnit;

  isConvert = false;

  displayValue: string;
  displayUnit: string;

  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    this.unit = VitalUnit.fromString(this.unitValue);
    if (this.isConvert) {
      switch (this.unit) {
        case VitalUnit.pounds:
          this.unit = VitalUnit.kilograms;
          this.displayValue = (Number.parseFloat(this.value) * VitalUnit.convertPoundToKilogram).toFixed(2);
          break;
        case VitalUnit.kilograms:
          this.unit = VitalUnit.pounds;
          this.displayValue = (Number.parseFloat(this.value) * VitalUnit.convertKilogramToPound).toFixed(2);
          break;
      }
    } else {
      this.displayValue = this.value;
    }

    if (this.abbreviate) {
      this.displayUnit = this.unit.abbreviation.toLowerCase();
    } else {
      this.displayUnit = this.unit.name;
    }
  }

  convert() {
    this.isConvert = !this.isConvert;
    this.ngOnChanges();
  }
}
