import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FeatureFlag } from './config.model';
import {ConfigLoaderService} from './config-loader.service';

@Injectable()
export class FeatureFlagService {
  featureFlags = {};

  constructor(private httpClient: HttpClient,
              private configService: ConfigLoaderService) {
  }

  load(): void {
    this.featureFlags = this.configService.getConfig().featureFlags;
  }

  getFeatureFlag(key: FeatureFlag) {
    // If the flag does not exist, then set it to false
    return this.featureFlags[key] ? this.featureFlags[key] : false;
  }
}
