import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './helpers/auth.guard';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    loadChildren: 'src/app/layout/layout.modules#LayoutModules'
  },
  {
    path: 'client-management',
    loadChildren: 'src/app/client-management/client-management.modules#ClientManagementModules'
  },
  {
    path: 'patient-record',
    loadChildren: 'src/app/patient-management/patient-management.modules#PatientManagementModules'
  },
  {
    path: 'configuration-management',
    loadChildren: 'src/app/configuration-management/configuration-management.modules#ConfigurationManagementModules'
  },
  {
    path: 'schedule',
    loadChildren: 'src/app/schedule/schedule.modules#ScheduleModules'
  },


];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
