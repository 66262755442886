import { Component, Inject, EventEmitter, Output, OnChanges, SimpleChanges, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Client } from 'src/app/client-management/models/client.interface';
import { Observable, VirtualTimeScheduler } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { startWith, map } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Tag } from './tag.model';
import { TagType } from '../../helpers/enums';
import { TagService } from './tag.service';

@Component({
    selector: 'app-tags',
    templateUrl: 'tags.component.html',
    styleUrls: ['tags.component.scss']
})
export class TagsComponent implements OnInit {

    @Output() outputTags = new EventEmitter<Tag[]>();
    @Input() tags: Tag[];
    @Input() tagType;

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    tagCtrl = new FormControl();
    filteredTags: Observable<Tag[]>;

    allTags: Tag[] = [];

    @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public tagService: TagService
    ) {
        this.filteredTags = this.tagCtrl.valueChanges.pipe(
            startWith(null),
            map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));
    }


    ngOnInit() {
        this.getTags();
    }

    getTags() {
      if (this.tagType == 'Client') {
        this.tagService.getClientTags().subscribe(
          response => {
            this.allTags = response.tags;
          }
         );
      }
      if (this.tagType == 'Patient') {
        this.tagService.getPatientTags().subscribe(
          response => {
            this.allTags = response.tags;
          }
         );
      }
    }

    add(event: MatChipInputEvent): void {

        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;

            // Add our fruit
            if (value && (value || '').trim()) {
                const tag = { text: value, type: this.tagType, id: ''} as Tag ;

                this.tags.push(tag);
                this.outputTags.emit(this.tags);

            }
        }

        if (event.input) {
            event.input.value = '';
        }
        this.tagCtrl.setValue(null);
    }

    remove(tag: Tag): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
            this.outputTags.emit(this.tags);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {

        this.tags.push(event.option.value);
        this.outputTags.emit(this.tags);
        this.tagInput.nativeElement.value = '';
        this.tagCtrl.setValue(null);

    }

    private _filter(value: any): Tag[] {


        if (typeof value === 'string' || value instanceof String) {
            const filterValue = value.toLowerCase();
            return this.allTags.filter(tag => tag.text.toLowerCase().includes(filterValue));

        }
        return this.allTags;

    }


}
