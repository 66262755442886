import { NgModule } from '@angular/core';
import { ChipsComponent } from './chips/chips.component';
import { MaterialModules } from '../helpers/material.modules';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './notes/notes.component';
import { TagsComponent } from './tags/tags.component';
import { SafePipe } from './pipes/dom-sanitizer.pipe';
import { PhonePipe } from './pipes/phone-formatter.pipe';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation.dialog';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { StringFilterByPipe } from './pipes/stringFilterBy.pipe';
import {ViewWeightVitalComponent} from './view-weight-vital/view-weight-vital.component';



@NgModule({
  imports: [
    MaterialModules,
    CommonModule,
    MatDatetimepickerModule,
    SatDatepickerModule,
    SatNativeDateModule,
  ],
  exports: [
    ChipsComponent,
    NotesComponent,
    TagsComponent,
    SafePipe,
    StringFilterByPipe,
    PhonePipe,
    ConfirmationDialogComponent,
    ViewWeightVitalComponent
  ],
  declarations: [
    ChipsComponent,
    NotesComponent,
    TagsComponent,
    ConfirmationDialogComponent,
    SafePipe,
    PhonePipe,
    StringFilterByPipe,
    ViewWeightVitalComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
  ]
})
export class SharedModules { }


