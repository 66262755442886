import {LocalStorageNames} from './local-storage.enum';

export const storage = {
  getItem<T>(key: string, fallbackValue: T): T {
    try {
      return JSON.parse(localStorage.getItem(key)) as T;
    } catch (e) {
      return fallbackValue;
    }
  },
  setItem<T>(key: string, value: T) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      // TODO
      localStorage.removeItem(LocalStorageNames.DailyCalendar);
      localStorage.removeItem(LocalStorageNames.WeeklyCalendar);
      localStorage.removeItem(LocalStorageNames.DashboardCalendar);
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (e) {
        console.log(`Could not save data for: ${key}`);
      }
    }
  },
  removeItem(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.log(`Could not remove key: ${key}\n ERR: ${e}`);
    }
  },
};
