import { NgModule } from '@angular/core';
import { LandingPageComponent } from './landing-page/landing-page';
import { CommonModule } from '@angular/common';
import { MaterialModules } from '../helpers/material.modules';
import { LoginComponent } from './landing-page/login/login.component';
import { AccountService } from '../configuration-management/services/account.service';
import { BaseService } from '../helpers/base.service';
import { HttpClientModule } from '@angular/common/http';
import { LayoutRouting } from './layout.routing';


// Application wide providers
const APP_PROVIDERS = [
  BaseService,
  AccountService,
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModules,
    HttpClientModule,
    LayoutRouting
  ],
  declarations: [
    LandingPageComponent,
    LoginComponent
  ],
  exports: [
    LandingPageComponent,
    LoginComponent
  ],
  providers: [
    APP_PROVIDERS
  ]
})
export class LayoutModules {}

