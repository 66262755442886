import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../configuration-management/services/account.service';
import { User } from '../../configuration-management/models/user.interface';
import { LayoutService } from '../services/layout.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUser: User;
  events: string[] = [];
  opened = true;
  shouldRun = true;
  search: boolean;

  constructor(
    public accountService: AccountService,
    private router: Router,
    public layoutService: LayoutService

  ) {
    this.accountService.currentUser.subscribe(x => this.currentUser = x);
  }



  ngOnInit() {
  }

  get route() {

    if (this.layoutService.route) {

      return this.layoutService.route;
    }

    return { routeName: '', routeText: ''};
  }
  
  logout() {
    this.accountService.logout();
    this.router.navigateByUrl('login');
  }

}
