import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import {ConfigModel} from './config.model';

@Injectable()
export class ConfigLoaderService {
  config = {} as ConfigModel;

  constructor(private httpClient: HttpClient) {
    this.config.apiUrl = '';
    this.config.baseUrl = '';
    this.config.environmentConfig = '';
    this.config.staging = false;
    this.config.dev = false;
    this.config.demo = false;
    this.config.qa = false;
    this.config.pwa = false;
    this.config.prod = false;
  }

  loadConfig(): Promise<ConfigModel> {
    return this.httpClient.get<ConfigModel>('./assets/appconfig.json')
      .pipe(tap((response) => {
        this.config.apiUrl = response.apiUrl;
        this.config.baseUrl = response.baseUrl;
        this.config.qa = response.qa;
        this.config.environmentConfig = response.environmentConfig;
        this.config.dev = response.dev;
        this.config.pwa = response.pwa;
        this.config.demo = response.demo;
        this.config.prod = response.prod;
        this.config.staging = response.staging;
        this.config.featureFlags = response.featureFlags;
      })).toPromise<ConfigModel>();
  }

  getConfig() {
    return this.config;
  }

  getSpecificConfig(key: keyof ConfigModel) {
    return this.config[key];
  }

}
