export const environment = Object.assign({}, {
  apiUrl: null,
  baseUrl: null,
  dev: null,
  staging: null,
  qa: null,
  demo: null,
  prod: null,
  pwa: null
});
